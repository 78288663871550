<template>
  <div class="homepage">
    <div class="container">
      <div class="homepage-header row padding-after-navbar mb-4">
        <div class="col-sm text-center">
          <h2 class="text-center mb-4">Welcome to Play.Amble</h2>
        </div>
      </div>

      <div class="homepage-about">
        <div class="row">
          <div class="col-sm mb-4">
            <p>
              Play.Amble is a gaming platform that hosts some of Amble's digital
              games. It is based on the <a href="http://storysynth.org">Story Synth</a> open source software platform,
              which you can use to make your own story games. Explore selected Amble games below, or visit our main site
              at <a href="http://amble.studio">Amble.Studio</a> to learn more, and find more games.
            </p>
          </div>
        </div>
      </div>

      <!-- Section: play -->
      <div class="homepage-play margin-between-sections">
        <!-- <h2 class="text-center mb-4">Play an existing game</h2> -->

        <div class="row">
          <div class="offset-lg-0 col-lg-12 col-md-4 mb-2 d-flex flex-column">
            <div class="card shadow h-100">
              <div class="row no-gutters">
                <div class="col-lg-4">
                  <a href="/Games/Green-Hollow/">
                    <img src="https://amble.studio/wp-content/uploads/2022/09/Green-Hollow-Play.Amble-Gallery.png"
                      alt="Green Hollow logo" class="card-img">
                  </a>
                </div>
                <div class="col-lg-8">
                  <div class="card-body d-flex flex-column h-100">
                    <h5 class="card-title">Green Hollow</h5>
                    <h6 class="card-subtitle text-muted mb-2">By Amble Studio</h6>
                    <div class="card-text">A tiny quest to change your village for the better, and explore real world
                      teamwork at the same time.</div>
                    <a href="/Games/Green-Hollow/" target="_self"
                      class="btn mt-auto align-self-start stretched-link ">Play game</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="offset-lg-0 col-lg-12 col-md-4 mb-2">
            <div class="card shadow h-100">
              <div class="row no-gutters">
                <div class="col-lg-4">
                  <a href="/Games/Office-Heroes/">
                    <img src="https://amble.studio/wp-content/uploads/2022/09/Office-Heroes-Play.Amble_.png"
                      alt="Office Heroes logo" class="card-img">
                  </a>
                </div>
                <div class="col-lg-8">
                  <div class="card-body d-flex flex-column h-100">
                    <h5 class="card-title">Office Heroes</h5>
                    <h6 class="card-subtitle text-muted mb-2">By Amble Studio</h6>
                    <div class="card-text">A funny, fast game of superheroes with wild office-based powers.</div>
                    <a href="/Games/Office-Heroes/" target="_self"
                      class="btn mt-auto align-self-start stretched-link ">Play game</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="offset-lg-0 col-lg-12 col-md-4 mb-2">
            <div class="card shadow h-100">
              <div class="row no-gutters">
                <div class="col-lg-4">
                  <a href="/Games/Tumbledown-Grove/">
                    <img src="https://amble.studio/wp-content/uploads/2022/09/Tumbledown-Grove-Play.Amble-logo.png"
                      alt="Tumbledown Grove logo" class="card-img">
                  </a>
                </div>
                <div class="col-lg-8">
                  <div class="card-body d-flex flex-column h-100">
                    <h5 class="card-title">Tumbledown Grove</h5>
                    <h6 class="card-subtitle text-muted mb-2">By Amble Studio</h6>
                    <div class="card-text">An interactive story about feelings that makes your child the main character
                      of their own fantastic tale.</div>
                    <a href="/Games/Tumbledown-Grove/" target="_self"
                      class="btn mt-auto align-self-start stretched-link ">Play game</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="offset-lg-0 col-lg-12 col-md-4 mb-2">
            <div class="card shadow h-100">
              <div class="row no-gutters">
                <div class="col-lg-4">
                  <a href="/Games/Umberdred-Institute/">
                    <img src="https://img.itch.zone/aW1nLzY3NzQzNTIuanBn/315x250%23c/XQicpj.jpg"
                      alt="Umberdred Institute for Emerging Evildoers logo" class="card-img">
                  </a>
                </div>
                <div class="col-lg-8">
                  <div class="card-body d-flex flex-column h-100">
                    <h5 class="card-title">Umberdred Institute for Emerging Evildoers</h5>
                    <h6 class="card-subtitle text-muted mb-2">By Amble Studio</h6>
                    <div class="card-text">Tell silly stories about how aspiring villains accept unlikely quests to earn
                      dubious certifications in evildoing skills.</div>
                    <a href="/Games/Umberdred-Institute/" target="_self"
                      class="btn mt-auto align-self-start stretched-link ">Play game</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>



        <!-- <div class="row mt-3">
          <div class="col-sm">
            <a href="/Gallery" class="btn py-3 px-4 btn-lg">See all games</a>
          </div>
        </div> -->
      </div>

      <!-- Section: grant -->
      <!-- <div class="margin-between-sections">
        <div class="row">
          <div class="col-12">
            <h2>Grant Program</h2>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p>Apply for a grant to make a game with Story Synth. This microgrant program is run by Big Bad Con and
              funded by Grant for the Web. <a href="/Grants/">Learn more and apply</a>.</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mb-2">
            <div class="card shadow">
              <a href="/Grants/">
                <img class="img img-fluid" src="/img/microgrant-banner.png"
                  alt="microgrant banner - click it to learn more and apply">
              </a>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Section: create -->
      <!-- <div class="homepage-create margin-between-sections">
        <div class="row">
          <div class="col-12">
            <h2>Create a new game</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p>Story Synth has many formats and templates. Check out these demos and copy a template to it as the basis
              for your game</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4 mb-2">
            <div class="card shadow">
              <img src="/img/shuffled-thumbnail.png" alt="a thumbnail of the shuffled format" class="card-img-top">
              <div class="card-body">
                <h3 class="card-title">Shuffled</h3>
                <a href="/Shuffled/1N5eeyKTVWo5QeGcUV_zYtwtR0DikJCcvcj6w69UkC1w" class="btn btn-block">View demo</a>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-2">
            <div class="card shadow">
              <img src="/img/generator-thumbnail.png" alt="a thumbnail of the generator format" class="card-img-top">
              <div class="card-body">
                <h3 class="card-title">Generator</h3>
                <a href="/Generator/1F0g3rVHxRA7O0rRMIQSsLCepJStxBO6pa7QJUNJb3K0/" class="btn btn-block">View demo</a>
              </div>
            </div>
          </div>
          <div class="col-md-4 mb-2">
            <div class="card shadow">
              <img src="/img/hexflower-thumbnail.png" alt="a thumbnail of the hexflower format" class="card-img-top">
              <div class="card-body">
                <h3 class="card-title">Hexflower</h3>
                <a href="/Hexflower/1wLDboZZBfBwMKswMYcRIXxz6DxRZJyAa6KPW6TvR-dM/" class="btn btn-block">View demo</a>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm">
            <a href="/Formats/" class="btn mb-5 py-3 px-4 btn-lg">More templates</a>
            <a href="https://docs.storysynth.org/guide/" class="btn ml-2 mb-5 py-3 px-4 btn-lg">Learn how to make your
              first game</a>
          </div>
        </div>

      </div> -->

      <!-- Section: test -->
      <!-- <div class="homepage-test margin-between-sections">
        <div class="row">
          <div class="col-12">
            <h2 class='mb-3'>Get a playable link</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p>Upload your game to Play.Amble at a secret but shareable link.</p>
          </div>
          <app-gameMaker v-if="!launcherOnHomepage" :routeRoomID="$route.params.roomID"
            :routeGSheetID="$route.params.gSheetID" :routeGameType="$route.params.gameType"></app-gameMaker>
        </div>

      </div> -->

      <!-- Section: publish -->
      <!-- <div class="homepage-publish margin-between-sections">
        <div class="row">
          <div class="col-12">
            <h2 class="mb-3">Publish your game</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <p>When you’re ready, fill out <a href="https://forms.gle/CizjsyFsR6sVCYEX7">this form</a> to add your game
              to the gallery!</p>
          </div>
          <div class="card shadow">
            <a href="/Gallery/">
              <img class="img img-fluid rounded-m clipped" src="/img/story-synth-gallery-banner.png"
                alt="Collage of Story Synth Games">
            </a>
          </div>
        </div>

      </div> -->


    </div>

    <app-gameLauncherSingleGame v-if="launcherOnHomepage" :gameRoute="String(launcherOnHomepage)">
    </app-gameLauncherSingleGame>

    <!-- <div class="homepage-made-by">
      Story Synth is made and by <a href="https://randylubin.com">Randy Lubin</a> with the help of an awesome community!
    </div> -->

  </div>
</template>

<script>
// import GameMaker from "../launchers/GameMaker.vue";
import GameLauncherSingleGame from "../launchers/GameLauncherSingleGame.vue"

export default {
  name: "app-homepage",
  components: {
    // "app-gameMaker": GameMaker,
    "app-gameLauncherSingleGame": GameLauncherSingleGame,
  },
  props: {
    routeGameType: String,
    routeGSheetID: String,
    routeRoomID: String,
  },
  data() {
    return {
      launcherOnHomepage: false, // if you want the game launcher on the homepage, add in the route up until the roomID e.g. "/Games/Around-The-Realm/"
    }
  },
  mounted() {
    let body = document.getElementById("app"); // document.body;
    body.classList.remove(body.classList[0]);
    body.classList.add("style-template-homepage");

    body = document.getElementsByClassName("non-footer-content")[0]
    if (body.classList.length > 0) {
      body.classList.remove(body.classList[1])
    }

    document.dispatchEvent(new Event("x-app-rendered"));
  }
};
</script>

<style scoped>
.homepage {
  max-width: 800px;
  margin: auto;
}

li {
  list-style-type: disc;
  display: list-item;
  margin-left: 20px;
}
</style>